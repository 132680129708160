<template>
	<div>
		<div class="subtitle rbc">
			<span>代购人信息</span>
			<el-button type="primary" @click="openDialog">+ 添加</el-button>
		</div>

		<div class="table-wrap">
			
			<el-table max-height="350" :data="list" stripe border style="width: 100%"
				:header-cell-style="{background:'#EBF6FC',color:'#999999'}">
				<!-- <el-table-column type="selection" width="45" /> -->
				<el-table-column type="index" width="45" />
				<el-table-column prop="username" label="姓名" width="170" />
				<el-table-column prop="phone" label="手机" width="170" />
				<!-- <el-table-column prop="name" label="物品名称" width="220" />
				<el-table-column prop="num" label="数量" width="120" /> -->
				<el-table-column prop="address" label="地址" width="360" />
				<el-table-column fixed="right" label="操作" width="120">
					<template slot-scope="scope">
						<el-button @click="editRow(scope.$index)" type="text" size="small">
							编辑</el-button>
						<el-button @click.native.prevent="deleteRow(scope.$index, list)" type="text"
							size="small">
							<span style="color: #ff5500">删除</span>
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			
		</div>
		
		<el-dialog :title="status ? '编辑客户' : '添加客户'" :visible.sync="dialogFormVisible" center append-to-body>
			<el-form :model="form" :rules="rules" ref="ruleForm1" :inline="true" label-width="90px">
				<el-form-item label="姓名" prop="username">
					<el-input clearable v-model="form.username" />
				</el-form-item>
				<el-form-item label="手机" prop="phone">
					<el-input clearable v-model="form.phone" />
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input clearable v-model="form.address" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleCancel">取 消</el-button>
				<el-button type="primary" @click="handleConfirm">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			var validateUsername = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入姓名'));
				} else {
					callback();
				}
			};
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号'));
				} else {
					callback();
				}
			};
			var validateAddress = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请选择地址'));
				} else {
					callback();
				}
			};
			var validateAddress = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入地址'));
				} else {
					callback();
				}
			};
			return {
				count: 10,
				loading: false,
				status: 0, // 添加 0 编辑 1
				list: [],
				form: {
					username: '',
					phone: '',
					address: '',
				},
				rules: {
					username: [{
						validator: validateUsername,
						trigger: 'blur'
					}],
					phone: [{
						validator: validatePhone,
						trigger: 'blur'
					}],
					address: [{
						validator: validateAddress,
						trigger: 'blur'
					}]
				},
				dialogFormVisible: false,
				formLabelWidth: '200px',
				editorIndex: -1,
			}
		},
		methods: {
			openDialog() {
				this.status = 0
				this.dialogFormVisible = true
			},
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			editRow(index) {
				this.status = 1
				this.dialogFormVisible = true
				this.editorIndex = index
				this.form = {
					...this.list[index]
				}
			},
			editConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.list.splice(this.editorIndex, 1, this.form)
						this.$message({
							message: this.status ? '编辑成功！' : '添加成功',
							offset: 100,
							type: 'success'
						});
			
						this.dialogFormVisible = false
						this.editorIndex = -1
						this.resetForm(formName)
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleConfirm() {
				if (this.status) this.editConfirm('ruleForm1')
				else this.addForm('ruleForm1')
			},
			addForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let obj = {
							...this.form
						}
			
						this.list.push(obj)
						this.$message({
							message: '添加成功！',
							offset: 100,
							type: 'success'
						});
						
						this.dialogFormVisible = false
						this.resetForm(formName)
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			handleCancel() {
				this.form = {
					username: '',
					phone: '',
					address: '',
				}
				
				this.dialogFormVisible = false
			}
		},
		
	}
</script>

<style lang="scss" scoped>
	.subtitle {
		width: 100%;
		height: 60px;
		padding-left: 20px;
		padding-right: 50px;
		// margin-bottom: 20px;
	
		span:nth-child(1) {
			font-size: 1rem;
			font-weight: bold;
			margin-left: 13px;
			position: relative;
			font-family: PingFang SC;
	
			&::after {
				content: "";
				display: block;
				width: 3px;
				height: 19px;
				background: #3F7CF8;
				position: absolute;
				top: 0;
				left: -13px;
			}
		}
	}
	
	.table-wrap {
		margin: 30px 30px 0;
		border: 1px solid #CCCCCC;
		height: 650px;
	}
</style>
